<template>
  <div>
    <load-profile />
    <!-- Search Container Card -->
    <n-search-container
      v-if="searchFields.length"
      :active="true"
      :show-header="false"
      show-search
      :loading="loading"
      @search="search"
      @reset="reset"
    >
      <n-search-input
        ref="search"
        v-model="params"
        :fields="searchFields"
      >
        <template #typeId="item">
          <b-form-group
            :label-for="item.field.key"
            :label="$t(item.field.label)"
          >
            <b-form-radio-group
              v-if="item.field.type === 'radio'"
              :id="item.field.key"
              :ref="item.field.key"
              v-model="params[item.field.key]"
              buttons
              button-variant="outline-primary rounded-0"
              class="flex-wrap"
            >
              <b-form-radio
                v-for="option in item.field.options"
                :key="option.value"
                :value="option.value"
              >{{ $t(option.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>

        <template #period="item">
          <b-form-group
            :label-for="item.field.key"
            :label="$t(item.field.label)"
          >
            <b-form-radio-group
              v-if="item.field.type === 'radio'"
              :id="item.field.key"
              :ref="item.field.key"
              v-model="params[item.field.key]"
              buttons
              button-variant="outline-primary rounded-0"
              class="flex-wrap"
            >
              <b-form-radio
                v-for="option in item.field.options"
                :key="option.value"
                :value="option.value"
              >
                {{ $t(option.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-0-5">
        <b-row
          v-if="$store.state.profile.id !== user.id"
          class="mb-2"
        >
          <b-col cols="6">
            <b-button
              type="button"
              variant="outline-secondary"
              :disabled="loading"
              @click="back"
            >
              <feather-icon icon="ChevronLeftIcon" />
              {{ $t('button.back') }}
            </b-button>
          </b-col>
          <b-col
            cols="6"
            class="text-right"
          >
            <b-button
              type="button"
              variant="outline-secondary"
              :disabled="loading"
              @click="getData"
            >
              <feather-icon icon="RefreshCcwIcon" />
              {{ $t('button.refresh') }}
            </b-button>
          </b-col>
        </b-row>
        <b-row
          v-else
          class="mx-2 my-1"
        >
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              type="button"
              variant="outline-secondary"
              :disabled="loading"
              @click="getData"
            >
              <feather-icon icon="RefreshCcwIcon" />
              {{ $t('button.refresh') }}
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label>{{ $t('general.entries') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          @sort-changed="sortChanged"
        >
          <template
            v-if="$store.state.profile.id !== user.id"
            #thead-top=""
          >
            <b-tr class="text-center">
              <b-th :colspan="typeId !== 5 && user.typeId !== 5 ? 18 : 18">
                {{ user.name }}
              </b-th>
            </b-tr>
          </template>
          <template #cell(isVendor)="data">
            <b-badge
              v-if="data.item.isVendor"
              pill
              variant="info"
              badge-glow
            >
              {{ $t(`general.external`) }}
            </b-badge>
            <b-badge
              v-else
              pill
              variant="primary"
              badge-glow
            >
              {{ $t(`general.internal`) }}
            </b-badge>
          </template>
          <template #cell(username)="data">
            <b-link
              v-if="typeId !== 5 && user.typeId !== 5"
              class="link-underline"
              @click="viewMember(data.item.userId)"
            >
              {{ data.item.username }}
            </b-link>
            <span v-else>
              {{ data.item.username }}
            </span>
          </template>
          <template #cell(turnover)="data">
            <span>
              {{ data.item.turnover | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(commission)="data">
            <span class="text-wala">
              {{ data.item.commission | currency(data.item.ccy) }}
            </span>
            <div
              v-if="canViewCommission"
              class="text-draw"
            >
              ({{ data.item.accrueCommission | currency(data.item.ccy) }})
            </div>
          </template>
          <template #cell(netWl)="data">
            <b-link
              class="link-underline"
              :class="{
                'text-wala': data.item.netWl >= 0,
                'text-meron': data.item.netWl < 0,
              }"
              @click="openBetHistory(data.item)"
            >
              {{ data.item.netWl | currency(data.item.ccy) }}
            </b-link>
          </template>
          <template #cell(newWl)="data">
            <b-link
              class="link-underline"
              :class="{
                'text-wala': data.item.newWl >= 0,
                'text-meron': data.item.newWl < 0,
              }"
              @click="openBetHistory(data.item)"
            >
              {{ data.item.newWl | currency(data.item.ccy) }}
            </b-link>
          </template>
          <template #cell(deposit)="data">
            <b-link
              class="link-underline text-wala"
              @click="
                openTransactionHistory(
                  data.item.userId,
                  data.item.username,
                  'deposit'
                )
              "
            >
              {{ data.item.deposit | currency(data.item.ccy) }}
            </b-link>
            <br>
            <b-link
              v-if="depositPromo"
              class="link-underline text-draw"
              @click="
                openPromotionHistory(data.item)
              "
            >
              ({{ data.item.promoAmount | currency(data.item.ccy) }})
            </b-link>
          </template>
          <template #cell(withdrawal)="data">
            <b-link
              class="link-underline text-meron"
              @click="
                openTransactionHistory(
                  data.item.userId,
                  data.item.username,
                  'withdrawal'
                )
              "
            >
              {{ data.item.withdrawal | currency(data.item.ccy) }}
            </b-link>
          </template>
          <template #cell(balance)="data">
            <span>
              {{ data.item.balance | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(outstanding1)="data">
            <span v-if="data.item.outstanding1">
              <span class="text-meron">
                {{
                  data.item.outstanding1.meronBetTotal | currency(data.item.ccy)
                }}
              </span>
              |
              <span class="text-wala">
                {{
                  data.item.outstanding1.walaBetTotal | currency(data.item.ccy)
                }}
              </span>
              <br>
              <span class="text-draw">
                {{
                  data.item.outstanding1.drawBetTotal | currency(data.item.ccy)
                }}
              </span>
            </span>
          </template>
          <template #cell(outstanding2)="data">
            <span v-if="data.item.outstanding2">
              <span class="text-meron">
                {{
                  data.item.outstanding2.meronBetTotal | currency(data.item.ccy)
                }}
              </span>
              |
              <span class="text-wala">
                {{
                  data.item.outstanding2.walaBetTotal | currency(data.item.ccy)
                }}
              </span>
              <br>
              <span class="text-draw">
                {{
                  data.item.outstanding2.drawBetTotal | currency(data.item.ccy)
                }}
              </span>
            </span>
          </template>
          <template #cell(outstanding3)="data">
            <span v-if="data.item.outstanding3">
              <span class="text-meron">
                {{
                  data.item.outstanding3.meronBetTotal | currency(data.item.ccy)
                }}
              </span>
              |
              <span class="text-wala">
                {{
                  data.item.outstanding3.walaBetTotal | currency(data.item.ccy)
                }}
              </span>
              <br>
              <span class="text-draw">
                {{
                  data.item.outstanding3.drawBetTotal | currency(data.item.ccy)
                }}
              </span>
            </span>
          </template>
          <template #cell(outstanding4)="data">
            <span v-if="data.item.outstanding4">
              <span class="text-meron">
                {{
                  data.item.outstanding4.meronBetTotal | currency(data.item.ccy)
                }}
              </span>
              |
              <span class="text-wala">
                {{
                  data.item.outstanding4.walaBetTotal | currency(data.item.ccy)
                }}
              </span>
              <br>
              <span class="text-draw">
                {{
                  data.item.outstanding4.drawBetTotal | currency(data.item.ccy)
                }}
              </span>
            </span>
          </template>
          <template #cell(outstandingJoker)="data">
            {{ data.item.outstandingJoker | currency(data.item.ccy) }}
          </template>
          <template #cell(member)="data">
            <b-link
              v-if="typeId !== 5 && user.typeId !== 5"
              class="link-underline"
              @click="viewMember(data.item.userId)"
            >
              {{ data.item.member }}
            </b-link>
            <span v-else>
              {{ data.item.member }}
            </span>
            <div
              v-if="$can('update', 'bet-setting')"
              class="text-success"
            >
              ({{ data.item.activePlayer }})
            </div>
          </template>
          <template #cell(status)="data">
            <b-badge
              v-if="data.item.status"
              pill
              variant="success"
              badge-glow
            >
              {{ $t(`status.active`) }}
            </b-badge>
            <b-badge
              v-else
              pill
              variant="danger"
              badge-glow
            >
              {{ $t(`status.inactive`) }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                v-if="
                  $can('update', getTypeById(data.item.typeId)) &&
                    getTypeById(data.item.typeId)
                "
                v-b-tooltip.hover
                :to="{
                  name: `update-${getTypeById(data.item.typeId)}`,
                  params: { id: data.item.userId },
                }"
                :title="$t('general.update')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="
                  ($can('create', 'deposit-withdrawal') || $can('edit', 'deposit-withdrawal')) &&
                    !data.item.isVendor
                "
                v-b-tooltip.hover
                :title="$t('button.deposit')"
                variant="primary"
                pill
                size="sm"
                @click="
                  showDepositOrWithdrawModel(
                    data.item.userId,
                    data.item.username,
                    data.item.balance,
                    data.item.ccy
                  )
                "
              >
                <feather-icon icon="CreditCardIcon" />
              </b-button>
              <b-button
                v-if="
                  ($can('create', 'deposit-withdrawal') || $can('edit', 'deposit-withdrawal')) &&
                    !data.item.isVendor
                "
                v-b-tooltip.hover
                :title="$t('button.withdraw')"
                variant="primary"
                pill
                size="sm"
                @click="
                  showDepositOrWithdrawModel(
                    data.item.userId,
                    data.item.username,
                    data.item.balance,
                    data.item.ccy,
                    'withdrawal'
                  )
                "
              >
                <feather-icon icon="RotateCcwIcon" />
              </b-button>
              <b-button
                v-if="data.item.typeId === 6"
                v-b-tooltip.hover
                :title="$t('button.betHistory')"
                variant="primary"
                pill
                size="sm"
                @click="openBetHistory(data.item)"
              >
                <feather-icon icon="BookOpenIcon" />
              </b-button>
              <!--              <b-button-->
              <!--                v-if="depositPromo"-->
              <!--                v-b-tooltip.hover-->
              <!--                :title="$t('field.promotionAmount')"-->
              <!--                variant="primary"-->
              <!--                pill-->
              <!--                size="sm"-->
              <!--                @click="openPromotionHistory(data.item)"-->
              <!--              >-->
              <!--                <feather-icon icon="DollarSignIcon" />-->
              <!--              </b-button>-->
            </div>
          </template>
          <template #custom-foot>
            <b-tr v-if="!loading">
              <b-th colspan="3" />
              <b-th class="text-right">
                <h5 class="font-weight-bold">
                  {{ totalReport.turnover | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th class="text-right">
                <h5
                  class="font-weight-bold"
                  :class="{
                    'text-wala': totalReport.netWl >= 0,
                    'text-meron': totalReport.netWl < 0,
                  }"
                >
                  {{ totalReport.netWl | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th class="text-right">
                <h5 class="font-weight-bold text-wala">
                  {{ totalReport.commission | currency(totalReport.ccy) }}
                </h5>
                <h5
                  v-if="canViewCommission"
                  class="font-weight-bold text-draw"
                >
                  ({{ totalReport.accrueCommission | currency(totalReport.ccy) }})
                </h5>
              </b-th>
              <b-th class="text-right">
                <h5
                  class="font-weight-bold"
                  :class="{
                    'text-wala': totalReport.newWl >= 0,
                    'text-meron': totalReport.newWl < 0,
                  }"
                >
                  {{ totalReport.newWl | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th class="text-right">
                <h5
                  class="font-weight-bold"
                  :class="{
                    'text-wala': totalReport.deposit >= 0,
                  }"
                >
                  {{ totalReport.deposit | currency(totalReport.ccy) }}
                </h5>
                <h5
                  v-if="depositPromo"
                  class="font-weight-bold text-draw"
                >
                  ({{ totalReport.promoAmount | currency(totalReport.ccy) }})
                </h5>
              </b-th>
              <b-th class="text-right">
                <h5
                  class="font-weight-bold"
                  :class="{
                    'text-meron': totalReport.withdrawal >= 0,
                  }"
                >
                  {{ totalReport.withdrawal | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th class="text-right">
                <h5 class="text-brand">
                  {{ totalReport.balance | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <!-- Agent no need to see member column of players list -->
              <b-th
                v-if="typeId !== 5 && user.typeId !== 5"
                class="text-center font-weight-bold"
              >
                <h5>
                  {{ totalReport.member }}
                  <div
                    v-if="$can('update', 'bet-setting')"
                    class="text-success"
                  >
                    ({{ totalReport.activePlayer }})
                  </div>
                </h5>
              </b-th>
              <!-- Agent should see outstanding column of players list -->
              <b-th class="text-center">
                <h5
                  v-if="totalReport.outstanding1"
                  class="font-weight-bold"
                >
                  <span class="text-meron">
                    {{
                      totalReport.outstanding1.meronBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                  |
                  <span class="text-wala">
                    {{
                      totalReport.outstanding1.walaBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                  <br>
                  <span class="text-draw">
                    {{
                      totalReport.outstanding1.drawBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                </h5>
              </b-th>
              <b-th class="text-center">
                <h5
                  v-if="totalReport.outstanding2"
                  class="font-weight-bold"
                >
                  <span class="text-meron">
                    {{
                      totalReport.outstanding2.meronBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                  |
                  <span class="text-wala">
                    {{
                      totalReport.outstanding2.walaBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                  <br>
                  <span class="text-draw">
                    {{
                      totalReport.outstanding2.drawBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                </h5>
              </b-th>
              <b-th class="text-center">
                <h5
                  v-if="totalReport.outstanding3"
                  class="font-weight-bold"
                >
                  <span class="text-meron">
                    {{
                      totalReport.outstanding3.meronBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                  |
                  <span class="text-wala">
                    {{
                      totalReport.outstanding3.walaBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                  <br>
                  <span class="text-draw">
                    {{
                      totalReport.outstanding3.drawBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                </h5>
              </b-th>
              <b-th class="text-center">
                <h5
                  v-if="totalReport.outstanding4"
                  class="font-weight-bold"
                >
                  <span class="text-meron">
                    {{
                      totalReport.outstanding4.meronBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                  |
                  <span class="text-wala">
                    {{
                      totalReport.outstanding4.walaBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                  <br>
                  <span class="text-draw">
                    {{
                      totalReport.outstanding4.drawBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                </h5>
              </b-th>
              <b-th class="text-center font-weight-bold">
                <h5>
                  {{ totalReport.outstandingJoker | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th colspan="1" />
              <b-th colspan="1" />
              <b-th
                v-if="typeId == 5 || user.typeId == 5"
                colspan="1"
              />
            </b-tr>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        />
      </div>
    </b-card>
    <deposit-or-withdraw
      ref="depositWithdrawModel"
      @success="depositOrWithdrawSuccess"
    />
    <bet-history ref="betHistoryModal" />
    <promotion-history ref="promotionHistoryModal" />
    <transaction-history ref="transactionHistoryModal" />
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BInputGroup,
  BLink,
  BRow,
  BTh,
  BTr,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Repository from '@/repositories/RepositoryFactory'
import NPagination from '@/components/NPagination.vue'
import NSearchContainer from '@/components/NSearchContainer.vue'
import NSearchInput from '@/components/NSearchInput.vue'
import NButtonDelete from '@/components/NButtonDelete.vue'
import NTable from '@/components/NTable.vue'
import { calculateDatesByPeriod } from '@/libs/helper'
import DepositOrWithdraw from '@/views/account/depositOrWithdraw.vue'
import moment from 'moment'
import db from '@/libs/firestore-db'
import { camelizeKeys } from 'humps'
import BetHistory from './betHistory/List.vue'
import PromotionHistory from './promotionHistory/List.vue'
import TransactionHistory from './transactionHistory/List.vue'
import playerTableFields from './playerTableField'
import searchInputs from './searchInput'
import tableFields from './tableField'

const BalanceReportRepository = Repository.get('balanceReport')

export default {
  components: {
    BLink,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    vSelect,
    BFormGroup,
    BInputGroup,
    BBadge,
    BFormRadioGroup,
    BFormRadio,
    BTr,
    BTh,
    DepositOrWithdraw,
    BetHistory,
    TransactionHistory,
    PromotionHistory,
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        userId: Number(this.$route.query.userId) || '',
        typeId: Number(this.$route.query.typeId) || '',
        startDate: this.$route.query.startDate || moment()
          .format('YYYY-MM-DD'),
        toDate: this.$route.query.toDate || moment()
          .format('YYYY-MM-DD'),
        period: this.$route.query.period || 'today',
        search: this.$route.query.search || '',
      },
      totalReport: {},
      user: this.$store.state.profile,
      searchFields: [],

      listenFbMatch: null,
      matchNo: 0,
      drawBetTotal: 0,
      meronBetTotal: 0,
      walaBetTotal: 0,
      refresh: false,
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage
      },
      set(value) {
        this.$store.commit('pagination/SET_PER_PAGE', value)
      },
    },
    activeSearch() {
      return (
        !!this.$route.query.userId
          || !!this.$route.query.startDate
          || !!this.$route.query.toDate
      )
    },
    typeId() {
      if (this.$store.state.profile.typeId) {
        return this.$store.state.profile.typeId
      }
      return this.userData?.typeId
    },
    canViewCommission() {
      if (this.user.typeId < 4) {
        return false
      }

      return this.$can('update', 'bet-setting')
    },
    depositPromo() {
      if (this.user.typeId !== 5) {
        return false
      }

      if (this.$store.state.profile.depositPromo) {
        return this.$store.state.profile.depositPromo || this.$can('update', 'bet-setting')
      }
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData?.depositPromo || this.$can('update', 'bet-setting')
    },
  },
  watch: {
    perPage(value) {
      this.list(1)
    },
    'params.period': function (value) {
      const dates = calculateDatesByPeriod(value)
      if (dates.length) {
        this.params.startDate = dates[0]
        this.params.toDate = dates[1]
      }
    },
    typeId(value) {
      this.setSearchFields(value)
    },
    matchNo(value) {
      if (value) {
        // this.refreshData();
      }
    },
    drawBetTotal(value) {
      if (value) {
        // this.refreshData();
      }
    },
    meronBetTotal(value) {
      if (value) {
        // this.refreshData();
      }
    },
    walaBetTotal(value) {
      if (value) {
        // this.refreshData();
      }
    },
  },
  mounted() {
    this.getData(this.params.page)
    if (this.$store.state.profile.typeId) {
      this.setSearchFields(this.$store.state.profile.typeId)
    }
    // this.listenMatch();
  },
  beforeDestroy() {
    // this.listenFbMatch && this.listenFbMatch();
  },
  methods: {
    listenMatch() {
      this.listenFbMatch && this.listenFbMatch()
      this.listenFbChannel = db
        .collection('fight_match')
        .doc('match_1')
        .onSnapshot(response => {
          const data = camelizeKeys(JSON.parse(response.get('data')))
          if (data) {
            this.matchNo = data.no
            this.walaBetTotal = data.walaBetTotal
            this.meronBetTotal = data.meronBetTotal
            this.drawBetTotal = data.drawBetTotal
          }
        })
    },

    getTypeById(type) {
      if (type == 2) {
        return 'company'
      }
      if (type == 3) {
        return 'senior'
      }
      if (type == 4) {
        return 'master'
      }
      if (type == 5) {
        return 'agent'
      }
      if (type == 6) {
        return 'player'
      }

      return ''
    },
    changeCol(value) {
      if (value === 5) {
        this.fields = [...playerTableFields]
      } else {
        this.fields = [...tableFields]
      }
    },
    setSearchFields(userTypeId) {
      const typeSearch = {
        key: 'typeId',
        label: 'field.type',
        type: 'radio',
        cols: 6,
      }
      if (userTypeId === 1 || userTypeId === 8) {
        typeSearch.options = [
          {
            value: 2,
            text: 'breadcrumb.company',
          },
          {
            value: 3,
            text: 'breadcrumb.senior',
          },
          {
            value: 4,
            text: 'breadcrumb.master',
          },
          {
            value: 5,
            text: 'breadcrumb.agent',
          },
          {
            value: 6,
            text: 'breadcrumb.player',
          },
        ]
      } else if (userTypeId === 2) {
        typeSearch.options = [
          {
            value: 3,
            text: 'breadcrumb.senior',
          },
          {
            value: 4,
            text: 'breadcrumb.master',
          },
          {
            value: 5,
            text: 'breadcrumb.agent',
          },
          {
            value: 6,
            text: 'breadcrumb.player',
          },
        ]
      } else if (userTypeId === 3) {
        typeSearch.options = [
          {
            value: 4,
            text: 'breadcrumb.master',
          },
          {
            value: 5,
            text: 'breadcrumb.agent',
          },
          {
            value: 6,
            text: 'breadcrumb.player',
          },
        ]
      } else if (userTypeId === 4) {
        typeSearch.options = [
          {
            value: 5,
            text: 'breadcrumb.agent',
          },
          {
            value: 6,
            text: 'breadcrumb.player',
          },
        ]
      } else if (userTypeId === 5) {
        typeSearch.options = [
          {
            value: 6,
            text: 'breadcrumb.player',
          },
        ]
      }
      this.searchFields = [...searchInputs, typeSearch]
    },
    getUserTypeId() {
      if (this.$store.state.profile.typeId) {
        userTypeId = this.$store.state.profile.typeId
      } else {
        userTypeId = null
      }
    },
    back() {
      this.params.userId = this.user.parentId
      this.params.page = 1
      this.updateQuerySting()
      this.getData()
    },
    viewMember(userId) {
      this.params.userId = userId
      this.params.page = 1
      this.updateQuerySting()
      this.getData()
    },
    updateQuerySting() {
      this.$router.replace({ query: this.params })
    },
    list(page = 1) {
      this.params.page = page
      this.updateQuerySting()
      this.getData()
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy
      this.params.sort = sortDirection
      this.updateQuerySting()
      this.getData()
    },
    search(searchText) {
      this.params.page = 1
      this.$refs.pagination.reset()
      this.params.search = searchText
      this.updateQuerySting()
      this.getData()
    },
    reset() {
      this.$refs.search.reset()
      this.params.page = 1
      this.$refs.pagination.reset()
      this.params.userId = null
      this.params.startDate = moment()
        .format('YYYY-MM-DD')
      this.params.toDate = moment()
        .format('YYYY-MM-DD')
      this.params.period = 'today'
      this.params.search = ''
      this.updateQuerySting()
      this.getData()
    },
    refreshData() {
      if (this.refresh) {
        return
      }

      this.refresh = true
      BalanceReportRepository.index(this.params)
        .then(response => {
          const { data } = response.data
          this.user = {
            ...data.user,
          }
          if (this.user.typeId) {
            this.changeCol(this.user.typeId)
          }
          setTimeout(() => {
            this.items = [...data.list]
          }, 200)
          this.total = data.total
          this.getTotal()
        })
        .catch(() => {
          this.refresh = false
        })
    },
    getData() {
      this.loading = true
      BalanceReportRepository.index(this.params)
        .then(response => {
          const { data } = response.data
          this.user = {
            ...data.user,
          }
          if (this.user.typeId) {
            this.changeCol(this.user.typeId)
          }
          setTimeout(() => {
            this.items = [...data.list]
          }, 200)
          this.total = data.total
          this.totalReport = {}
          this.loading = false
          this.getTotal()
        })
        .catch(() => {
          this.loading = false
        })
    },
    getTotal() {
      BalanceReportRepository.total({
        ...this.params,
      })
        .then(response => {
          const { data } = response.data
          this.totalReport = {
            ...data,
          }
          this.refresh = false
        })
        .catch(() => {
          this.refresh = false
        })
    },
    showDepositOrWithdrawModel(
      id,
      username,
      availableCredit,
      ccy,
      type = 'deposit',
    ) {
      this.$refs.depositWithdrawModel.changeData({
        type,
        username,
        amount: 0,
        promoAmount: 0,
        userId: id,
        remark: null,
        availableCredit,
        ccy,
      })

      this.$refs.depositWithdrawModel.show(
        `${this.$t('field.deposit')} (${username})`,
      )
    },
    depositOrWithdrawSuccess() {
      this.getData()
    },
    openBetHistory(item) {
      const params = {
        page: 1,
        order: null,
        sort: null,
        userId: item.userId,
        startDate: this.params.startDate,
        toDate: this.params.toDate,
        period: this.params.period,
      }

      this.$refs.betHistoryModal.show(params)
    },
    openPromotionHistory(item) {
      const params = {
        page: 1,
        order: null,
        sort: null,
        userId: item.userId,
        startDate: this.params.startDate,
        toDate: this.params.toDate,
        period: this.params.period,
      }

      this.$refs.promotionHistoryModal.show(params)
    },
    openTransactionHistory(userId, username, stateType) {
      this.$refs.transactionHistoryModal.changeData(userId, username, {
        page: 1,
        startDate: this.params.startDate,
        toDate: this.params.toDate,
        stateType,
        period: this.params.period,
      })

      this.$refs.transactionHistoryModal.show()
    },
  },
  setup() {
    const fields = [...tableFields]
    return {
      fields,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
